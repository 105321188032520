import { Avatar, Media } from '@happywait/hw-lib-front.core.api'
import { Document, Media as MediaLegacy } from '@happywait/hw-lib-front.core.api/legacy'
import { htmlInjectedData } from 'const/htmlInjectedData'
import { getBaseDev } from '../utils/helpers'

const pictureExtensions = [
  'jpg',
  'jpeg',
  'png'
]
const supportedDocumentThumbExtensions = [
  'pdf'
]

export type ThumbType =
  | 'home'
  | 'user'
  | 'user_2x'
  | 'user_3x'
  | 'logo'
  | 'logo_2x'
  | 'logo_3x'
  | 'contain'
  | 'contain_2x'
  | 'contain_3x'
  | 'crop'
  | 'crop_2x'
  | 'crop_3x'
  | 'timeline'
  | 'timeline_2x'
  | 'timeline_3x'
  | 'gallery'
  | 'gallery_2x'
  | 'gallery_3x'
  | 'gallery_zoom'
  | 'gallery_zoom_2x'
  | 'gallery_zoom_3x'
  | 'document_gallery'
  | 'document_gallery_2x'
  | 'document_gallery_3x'
  | 'icon'
  | 'daily_picture'

export type thumb = {
  path: string
  pathSet: string
}

  /**
   * 
   * deprecated use getMediaThumbPath
   */
export const generateThumbUrl = ({
  url,
  thumbType,
  watermark,
}: {
  url?: string | null
  thumbType: ThumbType
  watermark?: boolean
}) => (url ? `${getBaseDev()}thumbs/${watermark ? 'watermark/' : ''}${thumbType}${url}` : '')


export const getMediaThumbPath = (media: Media | undefined | null | Avatar | Document | MediaLegacy, thumbName: ThumbType, fallback: string|null = null): thumb => {
  if(!media) {
    return {
      path: fallback ?? '',
      pathSet: ''
    }
  }

  if(media.fileWebPath && media.fileWebPath.split('.').pop() === 'svg') {
    return {
      path: `${getBaseDev()}${media.fileWebPath}`,
      pathSet: `${getBaseDev()}${media.fileWebPath}`
    }
  }

  return getThumbPaths(media, thumbName, fallback)
}

export const getThumbPaths = (media: Media | Avatar | Document | MediaLegacy, thumbName: ThumbType, fallback: string|null = null): thumb => {
  if (!(media as Media).isDocument) {
    return getPictureThumbPaths(media.path, thumbName, fallback)
  } else if (
    media.path &&
    isSupportedDocumentThumbExtension(media.path)
  ) {
    return {
      path : `${htmlInjectedData.baseDocumentThumb}${thumbName}${media.path}`,
      pathSet : `${htmlInjectedData.baseDocumentThumb}${thumbName}${media.path} 1x,
            ${htmlInjectedData.baseDocumentThumb}${thumbName}_2x${media.path} 2x,
            ${htmlInjectedData.baseDocumentThumb}${thumbName}_3x${media.path} 3x`
    }
  }
  return { path: fallback ?? '', pathSet: '' }
}

export const getPictureThumbPaths = (path: string | null | undefined, thumbName: ThumbType, fallback: string|null = null): thumb => {

  if(!path) {
    return {
      path: fallback ?? '',
      pathSet: ''
    }
  }

  return {
    path : `${htmlInjectedData.basePictureThumb}${thumbName}${path}`,
    pathSet : `${htmlInjectedData.basePictureThumb}${thumbName}${path} 1x,
      ${htmlInjectedData.basePictureThumb}${thumbName}_2x${path} 2x,
      ${htmlInjectedData.basePictureThumb}${thumbName}_3x${path} 3x`
  }
}

const isSupportedDocumentThumbExtension = path => [...supportedDocumentThumbExtensions, ...pictureExtensions].includes(path.split('.').pop())