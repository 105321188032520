import { Flex } from '@chakra-ui/react'
import { useCountNewMessages, useMePromoterConfiguration, useMedias } from '@happywait/hw-lib-front.core.api'
import { useDocumentSignaturesNumber } from '@happywait/hw-lib-front.core.api/legacy'
import { useQueryClient } from '@tanstack/react-query'
import { ChecklistMenuIcon } from 'assets/svg/ChecklistMenuIcon'
import { Logout } from 'assets/svg/Logout'
import { Pencil } from 'assets/svg/Pencil'
import { CoCustomerBadge } from 'components/CoCustomerBadge/CoCustomerBadge'
import { useCustomer } from 'hooks/useCustomer'
import { useLogout } from 'hooks/useLogout'
import useNavigateParams from 'hooks/useNavigateParams'
import { useLocation } from 'react-router-dom'
import { useStore } from 'store'
import { selectInitialData, selectShowModal } from 'store/selectors'
import { Adf } from '../../assets/svg/Adf'
import { Docs } from '../../assets/svg/Docs'
import { HeaderMessage } from '../../assets/svg/HeaderMessage'
import { Home } from '../../assets/svg/Home'
import { Other } from '../../assets/svg/Other'
import { Palette } from '../../assets/svg/Palette'
import { Photos } from '../../assets/svg/Photos'
import { Services } from '../../assets/svg/Services'
import { SnagSvg } from '../../assets/svg/Snag'
import { NavigationItem } from '../../design-system/NavigationItem/NavigationItem'
import { NavigationUser } from '../../design-system/NavigationUser/NavigationUser'
import { NavigationUserStyles } from '../../design-system/NavigationUser/NavigationUser.styles'
import { useBaseDev } from '../../hooks/useBaseDev'
import { useDevice } from '../../hooks/useDevice'
import { routeNames } from '../../modules'
import { getModuleTitle } from '../../modules/tools'
import { useTranslate } from '../../services/i18n'
import { getMediaThumbPath } from '../../tools/thumb'
import { when } from '../../utils/helpers'
import { ProjectSelector } from '../ProjectSelector/ProjectSelector'
import { Separator } from '../Separator/Separator'
import { menuServices as services } from './Menu.services'
import { MenuStyles as Styles } from './Menu.styles'
import { MenuMoreMobileModal } from './MenuMoreMobile'
const { useComponent } = services

const MessageMenuItem = ({ onClick }: { onClick: () => void }) => {
  const t = useTranslate()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { data: messagesNumber } = useCountNewMessages({
    queryParams: {
      refetchInterval: 10 * 60 * 1000,
      onSuccess: () => {
        queryClient.invalidateQueries(['conversation'])
      },
    },
  })

  return (
    <NavigationItem
      id="nav_messages_button"
      active={pathname.includes(routeNames.messaging)}
      title={getModuleTitle('messaging') || t('messages')}
      icon={HeaderMessage}
      notifications={when(!!messagesNumber && messagesNumber > 0, messagesNumber)}
      onClick={onClick}
    />
  )
}

const DocumentMenuItem = ({ onClick }: { onClick: () => void }) => {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const t = useTranslate()
  const { data: documentsToSignNumber } = useDocumentSignaturesNumber({
    queryParams: {
      refetchInterval: 5 * 60 * 1000,
      onSuccess: () => {
        queryClient.invalidateQueries(['document'])
      },
    },
  })

  const showNotifications = false

  return (
    <NavigationItem
      id="nav_documents_button"
      active={pathname.includes('documents')}
      title={getModuleTitle('documents') || t('documents')}
      icon={Docs}
      notifications={when(showNotifications, documentsToSignNumber)}
      onClick={onClick}
    />
  )
}

export const Menu = () => {
  const { showNavigationItem } = useComponent()
  const t = useTranslate()
  const navigate = useNavigateParams()
  const isMobileS = useDevice('mobileS')
  const isMobileL = useDevice('mobileL')
  const isMobile = isMobileS || isMobileL
  const isTabletL = useDevice('tabletL')
  const { pathname } = useLocation()
  const showModal = useStore(selectShowModal)
  const baseDev = useBaseDev()
  const { mainCustomer } = useCustomer()
  const { data: mePromoter } = useMePromoterConfiguration()
  const initialData = useStore(selectInitialData)
  const { logoutCustomer } = useLogout()

  const { data: promoterLogo } = useMedias({
    params: { ids: [initialData?.logo || 0] },
    queryParams: { enabled: !!initialData?.logo },
  })

  const navigateToExternal = (url: string | undefined) => window.open(url)
  const isTablet = useDevice('tabletL')
  const thumb = getMediaThumbPath(promoterLogo?.data[0], 'logo')

  return (
    <Flex
      backgroundColor="white"
      zIndex={2}
      overflow={'auto'}
      position={isMobile ? 'fixed' : 'inherit'}
      bottom={isMobile ? 0 : 'inherit'}
      flex={'1 1 auto'}
      direction={!isMobile ? 'column' : 'row'}
      width={'100%'}
    >
      {!!thumb.path && (
        <Styles.HeaderMenu>
          <Styles.Logo onClick={() => navigate('/home')} src={thumb.path} />
        </Styles.HeaderMenu>
      )}
      {/* HOME MODULE */}
      {showNavigationItem('HOME') && (
        <>
          {!isMobileL && (
            <>
              <ProjectSelector />
              {!mainCustomer && (
                <>
                  <Separator height={24} />
                  <CoCustomerBadge ml={!isTabletL ? '28px' : '0px'} subTitle displayIcon={!isTabletL} />
                </>
              )}
            </>
          )}
          <Separator height={24} />
          <NavigationItem
            id="nav_home_button"
            active={pathname.includes('home')}
            title={getModuleTitle('home') || t('home')}
            icon={Home}
            onClick={() => navigate('/home')}
          />
        </>
      )}
      {/* PHOTOS MODULE */}
      {showNavigationItem('PHOTO_V1', 'PHOTO_V2') && !isMobileL && (
        <NavigationItem
          id="nav_picture_button"
          active={pathname.includes('pictures')}
          title={getModuleTitle('pictures') || t('pictures')}
          icon={Photos}
          onClick={() => navigate('/pictures')}
        />
      )}
      {/* MEDIA MODULE */}
      {showNavigationItem('MEDIA') && <DocumentMenuItem onClick={() => navigate('documents')} />}
      {/* SERVICE MODULE */}
      {showNavigationItem('SERVICE') && !isMobileL && (
        <NavigationItem
          id="nav_services_button"
          active={pathname.includes('services') || pathname.includes('addons')}
          title={getModuleTitle('services') || t('services')}
          icon={Services}
          onClick={() => navigate('services')}
        />
      )}
      {/* TOOL MODULE */}
      {showNavigationItem('TOOL') && !isMobileL && (
        <NavigationItem
          id="nav_tool_button"
          active={pathname.startsWith(routeNames.choicesOptions)}
          title={getModuleTitle('choices-options') || t('choicesAndOptions')}
          icon={<Palette />}
          onClick={() => navigate(routeNames.choicesOptions)}
        />
      )}
      {/* FUNDING_APPEAL MODULE */}
      {showNavigationItem('FUNDING_APPEAL') && !isMobileL && (
        <NavigationItem
          id="nav_funding_appeal_button"
          active={pathname === routeNames.fundingAppeal}
          title={getModuleTitle('funding-appeal') || t('fundingAppeal')}
          icon={Adf}
          onClick={() => navigate(routeNames.fundingAppeal)}
        />
      )}
      {/* MESSAGE MODULE */}
      {showNavigationItem('MESSAGE') && <MessageMenuItem onClick={() => navigate(routeNames.messaging)} />}

      {/* SNAG MODULE */}
      {showNavigationItem('SNAG') && !isMobileL && (
        <NavigationItem
          id="nav_snag_button"
          active={pathname === routeNames.snags}
          title={getModuleTitle('snags') || t('snags')}
          icon={<SnagSvg h="24px" w="24px" />}
          onClick={() => navigate(routeNames.snags)}
        />
      )}

      {/* AFTER SALES */}
      {showNavigationItem('EXTERNAL_AFTER_SALE') && !isMobileS && (
        <NavigationItem
          id="nav_sav_button"
          title={getModuleTitle('sav') || t('sav')}
          icon={<Palette />}
          onClick={() => navigateToExternal(mePromoter?.afterSaleServiceUrl)}
        />
      )}

      {/* CHECKLIST MODULE*/}
      {showNavigationItem('CHECKLIST') && !isMobileL && (
        <NavigationItem
          id="nav_checklist_button"
          active={pathname.includes('checklist')}
          title={t('checklist')}
          icon={ChecklistMenuIcon}
          onClick={() => navigate('/checklist')}
        />
      )}

      {/* SHOWYOU MODULE*/}
      {showNavigationItem('SHOWYOU') && !isMobileL && (
        <NavigationItem
          id="nav_showyou_button"
          active={pathname === routeNames.personalization}
          title={t('personalization')}
          icon={Pencil}
          onClick={() => navigate(routeNames.personalization)}
        />
      )}

      {isMobile && (
        <>
          <NavigationItem
            id="nav_others_button"
            title={t('others')}
            icon={Other}
            onClick={() => {
              showModal({ modalId: 'menuMoreMobile' })
            }}
          />
          <MenuMoreMobileModal />
        </>
      )}

      {/* PROFIL MODULE */}

      <Styles.NavigationUserContainer>
        <NavigationUserStyles.Container>
          {showNavigationItem('PROFIL') && <NavigationUser onClick={() => navigate(routeNames.account)} />}
          <NavigationUserStyles.Button onClick={logoutCustomer}>
            {Logout}
            <Separator width={isTablet ? 4 : 8} />
            {t('logout')}
          </NavigationUserStyles.Button>
        </NavigationUserStyles.Container>
      </Styles.NavigationUserContainer>
    </Flex>
  )
}
